$unsubscribe-error-color: #707070;
.unsubscribe-error-body {
  width: 48%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 16px;
  @media (max-width: 768px) {
    width: auto;
  }
 
  .error-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $unsubscribe-error-color;
  }
 
  .error-text {
    font-weight: 500;
    line-height: 20px;
    color: $unsubscribe-error-color;
  }
 
  .button {
    width: 100px;
  }
}