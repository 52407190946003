.re-register-device.ui.modal.niagara-modal {
  padding: 0;
  width: 50%;
  height: 80%;
  .scrolling.content {
    height: calc(100% - 140px);
  }
  .re-register-device-form {
    height: 100%;
    .hint-image {
      padding: 24px;
      height: calc(100% - 82px);
      overflow-y: auto;
      .user-code-margin {
        margin: 0px;

        .steps-container {
          display: flex;

          &:nth-child(2) {
            padding-top: 16px;
          }

          .step-title {
            padding: 0px 0px 0px 12px;
            width: 80px;
          }

          .step-text {
            padding: 0px;
            width: calc(100% - 80px);
            b {
              word-break: break-all;
            }
          }
        }
      }

      .user-code-note {
        background-color: #dfe0df;
        border-radius: 10px;
        padding: 0.75rem;
      }
      .heading-get-user-code {
        font-weight: 800;
        color: #303030;
      }
      .below-text {
        min-height: 0px;
        margin: 0px;
      }
      .get-user-code-details {
        display: flex;
        gap: 100px;
        width: 100%;
        width: 100%;
      }
    }
    .form-container {
      margin: 11px 24px auto 24px;
      gap: 100px;
      .full-width {
        width: 50%;
        .input-box {
          border: 1px solid #d0d0d0 !important;
          box-shadow: none;
        }
      }
    }
    .niagara-input .ui.single-input .input-wrap .input-box {
      height: 50px !important;
    }
    .niagara-loader {
      height: 300px;
      .loader {
        padding: 100px 0px;
      }
    }
    .no-license {
      margin: 20px 0px;
      background-color: #f4f4f4;
      padding: 100px 10px 100px 15px;
      margin: 10px 24px 32px 24px;
      .license-link {
        color: #005eac;
        font-weight: 700;
      }
    }
    .error-body {
      width: 70%;
      margin-left: 15%;
    }
    .license-title {
      font-weight: 800;
      margin-top: 1rem;
      margin: 32px 24px auto 24px;
    }
  }
  .header {
    margin: 0 24px auto 24px;
    padding-top: 11px;
  }
  .actions {
    margin-top: 20px;
    padding: 0 32px 18px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .dimmable:has(.dimmer .re-register-device.ui.modal.niagara-modal) .ui.page.modals.dimmer {
    left: 0 !important;
    top: 0 !important;
    max-height: 100%;
    height: 100%;
    width: 100%;
    padding-top: 8px;
    overflow: hidden;
    background-color: #f2f2f2;
    .ui.modal.niagara-modal.re-register-device {
      height: 100%;
      max-height: 100%;
      border-radius: 12px 12px 0 0 !important;
      width: 100% !important;
      margin: 0 !important;
      .scrollable-modal {
        min-width: auto;
        height: 100%;
        .header {
          .modal-heading {
            order: 2;
            width: 100%;
            text-align: center;
            margin-right: 32px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .scrolling.content {
          min-height: calc(100% - 150px);
          max-height: 70vh;
          .re-register-device-form {
            .form-container .full-width {
              width: 100%;
            }
            .hint-image {
              .user-code-margin {
                .col-12 .col-6 {
                  flex-basis: 100%;
                  max-width: 100%;
                  .mobile-hint-img {
                    width: 100%;
                    margin: 10px 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
