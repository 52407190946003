.project-device-page:has(.mobile-responsive-device-table) {
  .page-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.mobile-responsive-device-table {
  > .device-list-header {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 20px 24px;
    @media only screen and (max-width: 376px) {
      .flex-fill {
        width: 100%;
        .ui.input input { 
          max-width: 100%;
        }
      }    
  }
    .device-filters {
      display: flex;
      gap: 12px;
    }
  }
  .mobile-device-table.niagara-data-table {
    height: calc(100% - 140px);
    .p-datatable-thead th .p-column-title {
      max-width: 100% !important;
    }
    .p-datatable-scrollable-header {
      height: auto !important;
    }
    .p-datatable-thead th {
      padding: 3px 0 3px 14px !important;
    }
    .p-column-title {
      height: auto !important;
      white-space: normal !important;
      > button {
        flex-shrink: 0;
        margin-left: 10px;
      }
    }
    .cell-data-wrap {
      padding: 8px 14px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      .column-container {
        width: 80%;
        .column-tooltip {
          width: 100%;
        }
      }
    }
    .h-icon.common {
      font-size: 1rem !important;
      font-weight: 600;
    }
    .sub-fields {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 16px;
      background-color: #f8f8f8;
      border-bottom: 1px solid #e0e0e0;
      width: 100%;
      align-items: center;
      .sub-field-header {
        display: flex;
        font-family: Honeywell Sans Web;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.02em;
        text-align: right;
        color: #303030;
        margin-left: 8px;
        width: 30%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .sub-field-data {
        display: flex;
        width: 70%;
        justify-content: flex-end;
        .subscribed-services {
          display: flex;
        }
 
        .sub-field-text {
          font-family: Honeywell Sans Web;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          color: #606060;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }
      }
    }
  }
 
  .data-table-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px;
    .loader-content {
      display: flex;
      flex-direction: row;
      .loading-text {
        display: flex;
        justify-content: center;
        align-content: center;
        margin-left: 8px;
      }
    }
    .load-more {
      width: 40%;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.5px;
      text-align: center;
      border: 1px solid #f0f0f0;
      box-shadow: 1px 1px 1px 0px #00000026;
      background: #ffffff;
      justify-content: center;
      .loading.pending-loader {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 16px;
        height: 16px;
        .loader {
          padding-top: 0;
          min-width: 16px;
          height: 16px !important;
          &:before,
          &:after {
            min-width: 16px;
            width: 16px;
            height: 16px;
            border-width: 2px;
            left: 0;
            margin: 0;
          }
        }
      }
    }
  }
  .device-list-empty {
    height: 100%;
    padding: 0 16px ;
    margin: 0 auto;
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .device-list-empty-title {
      font-weight: 700;
      font-size: 24px;
      text-align: center;
      color: #707070;
    }
    .device-list-empty-text {
      color: #707070;
    }
  }
}

#no-record-box {
  .message-box {
    width: 100%;
    padding: 0 16px;
  }
}

.projects-list-modal.project-list {
  height: 100%;
  .ui.raised.niagara-card{
     overflow: hidden;
     border-radius: 12px 12px 0 0 !important;
     box-shadow: none !important;
     border: none !important;
     height: 100%;
  }

  .header {
    position: absolute;
    top: 10px;
    z-index: 1;
  }
  .project-list-header {
     padding: 24px;
     text-align: center;
     font-size: 20px;
     font-weight: 700;
  }

  .project-list-footer {
     margin: 20px;
     display: flex;
     flex-direction: column;
     align-items: center;
     .create-project {
      min-width: 300px;
      display: flex;
      justify-content: center;
     }
  }

  .project-list-body {
     padding: 24px 0;
     display: flex;
     flex-direction: column;
     align-items: center;
     gap: 20px;
     height: calc(100% - 108px);
     overflow-y: auto;

     .search-wrap {
        width: calc(100% - 48px);

        .input {
           width: 100%;
        }
     }

     .sidebar-project {
        padding-left: 24px;
        padding-right: 24px;
        flex: 1;

        & > .sidebar {
           border: 0;
           width: 100%;
        }
     }

     .sidebar-project > .sidebar .ui.menu {
        display: flex;
        flex-direction: column;
        gap: 0;

        .item {
           text-transform: none;
           padding: 0;
           padding-right: 10px;
           border: none !important;

           &.active {
              .all-projects-selection,
              .project-selection-name {
                 border-left: 2px solid #1792e5;
              }
           }

           .all-projects-selection,
           .project-selection-name {
              border-left: 2px solid transparent;
              padding-left: 8px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 0px;
           }

           .all-projects-selection {
              margin-bottom: 8px;
              width: auto;
              display: flex;

              div {
                 flex: 1 1;
              }
           }

           .project-selection-name {
              flex: 1;
              margin-top: 6px;
              margin-bottom: 6px;
              line-height: 28px;
              display: flex;

              ~ .niagara-button-2 {
                 margin: auto 0;
                 min-width: 28px;
                 height: 28px;
              }

              span {
                 flex: 1 1;
              }
           }

           &:not(:hover) .project-selection-name ~ .niagara-button-2 {
              display: none;
           }

           &:hover,
           &.active {
              background: white;
           }
        }
     }
  }

  .project-list-footer {
     .button {
        width: calc(100% - 30px);
     }
  }
}

@media only screen and (max-width: 1199px) {
  .dimmable:has(.dimmer .projects-list-modal.ui.modal.niagara-modal) .ui.page.modals.dimmer {
    left: 0 !important;
    top: 0 !important;
    max-height: 100%;
    height: 100%;
    width: 100%;
    padding-top: 8px;
    overflow: hidden;
    background-color: #f2f2f2;
    .ui.modal.niagara-modal.projects-list-modal {
      height: 100%;
      max-height: 100%;
      border-radius: 12px 12px 0 0 !important;
      width: 100% !important;
      margin: 0 !important;
      padding: 0px;
      .scrolling.content {
        min-width: auto;
        height: 100%;
        padding: 0 !important;
        max-height: 100vh;
        border-radius: 12px 12px 0 0;
        .header {
          .modal-heading {
            order: 2;
            width: 100%;
            text-align: center;
            margin-right: 32px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}