.project-device-page {
   display: flex;
   flex-direction: column;

   .project-device-lists {
      display: flex;
      flex-direction: row;
      grid-gap: 16px;
      gap: 16px;

      .device-list {
         height: inherit;
         width: 100%;

         &.open-right {
            .niagara-snackbar {
               top: 58px;
               margin-right: 500px;
            }
         }

         .device-list-header {
            display: flex;
            flex-direction: row;
            gap: 12px;
            padding: 20px 24px;

            .niagara-sort-by {
               width: 190px;
            }
         }

         .niagara-data-table {
            height: calc(100% - 72px);

            .item-count-row {
               padding: 0px 24px;
               height: 30px;
            }

            .forge-datatable-wrap {
               .p-datatable-scrollable-view > .p-datatable-scrollable-header {
                  height: 44px;
               }

               .p-datatable-scrollable-view > .p-datatable-scrollable-body {
                  max-height: calc(100% - 44px) !important;
                  height: calc(100% - 44px);
               }
            }

            .p-datatable-row:has(#highlighted-device) {
               background-color: #88ddff26 !important;
            }

            .ncs-pagination-root {
               border-radius: 10px !important;
            }

            .pagination {
               min-width: auto;
            }

            .p-datatable .p-datatable-tbody {
               tr:not(.p-datatable-row) {
                  background-color: #f7f7f7 !important;
               }

               tr.row-open {
                  background-color: #f7f7f7 !important;
                  border-bottom: 1px solid #e0e0e0 !important;
               }

               tr .cell-data-wrap .host-id {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
               }
            }

            .p-datatable .p-datatable-thead {
               tr th:nth-last-child(2) {
                  border-right: none !important;
               }
            }
         }

         .point-table.niagara-data-table {
            margin-left: 24px;
            border-left: 2px solid #e0e0e0;
            max-height: 380px;
            padding-bottom: 0;
            background: #f7f7f7;

            .ui.forge-datatable-wrap {
               height: 100% !important;

               .p-datatable-scrollable {
                  &:has(.p-datatable-emptymessage) {
                     height: auto;
                  }

                  height: 380px;
               }

               .p-datatable-scrollable-view > .p-datatable-scrollable-header {
                  height: auto;
               }

               .p-datatable .p-selection-column .p-checkbox {
                  left: calc(50% - 12px);
                  top: calc(50% - 12px);
               }
            }

            tbody.p-datatable-tbody > tr {
               background-color: #f7f7f7 !important;
               border-bottom: 1px solid #e0e0e0 !important;

               &:nth-child(even) {
                  background-color: #f7f7f7 !important;
               }

               &.p-datatable-emptymessage {
                  text-align: center;
               }

               td {
                  font-size: 14px;

                  .cell-data-wrap {
                     padding: 4px 16px;
                     text-overflow: ellipsis;
                     overflow: hidden;
                     text-wrap: nowrap;
                  }
               }

               .compare-data-service {
                  display: flex;

                  .niagara-button-2 {
                     margin: auto;
                  }
               }

               &:not(:hover) .compare-data-service > .niagara-button-2 {
                  opacity: 0;
               }
            }

            .p-datatable-thead {
               tr {
                  th {
                     border-top: 2px solid #d0d0d0 !important;
                     border-bottom: 2px solid #d0d0d0 !important;
                     padding: 4px 16px;

                     &:not(:last-child) {
                        border-right: 2px solid #d0d0d0 !important;
                     }

                     &:last-child {
                        .p-column-title .sortable-header {
                           justify-content: flex-start;
                           gap: 20px;
                        }
                     }

                     &:nth-child(2) {
                        padding-left: 16px !important;
                     }
                  }
               }
            }

            .p-datatable-scrollable-header-box {
               padding-right: 0;
            }

            .item-count-row {
               display: none;
            }
         }

         .subscribed-services {
            display: flex;
            gap: 10px;

            .nds-services {
               border: 1px solid;
               display: flex;
               border-color: #e0e0e0;
               border-radius: 4px;
               gap: 8px;
            }

            .niagara-button-2 {
               margin: auto 0;
               min-width: 28px;
               height: 28px;

               &.disabled {
                  cursor: unset;
               }
            }
         }

         .loading {
            height: 289px !important;
         }

         .device-list-empty {
            height: 100%;
            width: 400px;
            margin: 0 auto;
            display: flex;
            gap: 16px;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .device-list-empty-title {
               font-weight: 700;
               font-size: 32px;
               line-height: 40px;
               text-align: center;
               color: #707070;
            }

            .device-list-empty-text {
               text-align: center;
               color: #707070;
            }
         }
      }
   }
}

.device-update-snackbar-message,
.device-update-toast-message {
   white-space: pre-wrap !important;
}

.device-update-toast-message {
   line-height: 24px;
   font-size: 16px;
   font-weight: 700;
   letter-spacing: 0.5px;
   color: #303030;
}

.device-update-snackbar-retry-button {
   color: #64c3ff !important;
   height: 24px !important;
}

.device-update-toast-view-device-button,
.device-update-toast-retry-button {
   align-self: flex-end;
}

.device-delete-toast {
   word-break: break-all;
   white-space: pre-wrap;

   .niagara-button-2 {
      margin-left: auto;
      padding: 0;
   }
}

tr td.action-column:nth-last-child(1),
tr th.action-column:nth-last-child(1) {
   .cell-data-wrap {
      padding: 0 !important;
   }

   width: 32px !important;
   padding: 0 !important;
}

tr td.action-column:first-child,
tr th.action-column:first-child {
   .cell-data-wrap {
      padding: 0 !important;
   }

   width: 64px !important;
   padding: 0 0 0 24px !important;
}

[data-theme='dark'] {
   @import '../DarkModeColor';
   /*
  * Using Below styles for device list page.
  */
   .project-device-page {
      .ui.raised.card.niagara-card {
         border: none !important;
      }
      .create-project {
         svg > path {
            fill: #c8c8c8;
         }
      }
      .project-device-lists .device-list {
         .subscribed-services {
            .nds-services {
               border-color: $background-color-1;
            }
            .niagara-button-2.primary-link.no-content.with-active-effect {
               svg > path {
                  stroke: #64c3ff;
               }
            }

            .niagara-button-2.primary-link.disabled.no-content.with-active-effect {
               svg > path {
                  stroke: #8f8f8f;
               }
            }
            &:has(.backup-link-remote) {
               // .backup-link:nth-last-child(1) {
                  svg > path {
                     stroke: none !important;
                     fill: #64c3ff;
                  // }
               }
            }
            &:has(.backup-link.disabled) {
               .backup-link:nth-last-child(1) {
                  svg > path {
                     stroke: none !important;
                     fill: #8f8f8f;
                  }
               }
            }
         }
         .device-more-actions-button .popup-pointer {
            svg > circle {
               stroke: #ffffff;
            }
         }

         .project-device-lists {
            .sidebar-layout > .sidebar {
               border-right: none;
               background: #404040;
               border-color: #303030;
               box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.15);
               color: #f0f0f0;
               box-shadow: none;

               .ui.divider {
                  display: none;
               }
            }
         }
      }
   }
}


