.unsubscribe-notifications-modal.ui.large.modal {
  width: 85%;
  padding: 24px;
  min-height: 420px;
  .header {
    justify-content: right;
  }
  .scrolling.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 80px);
    min-height: 332px;
    svg {
      width: 110px;
      height: 110px;
      margin-bottom: 40px;
    }
    .heading {
      font-size: 40px;
      line-height: 52px;
      display: flex;
      align-items: center;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      gap: 40px;
      margin-top: 36px;
    }
  }
}